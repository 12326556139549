//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { AppNav, Logo } from "./";

//3rd Party Components

//Atoms

//Services

//Helpers

//Other

function AppHeader() {
	const { t } = useTranslation();

	return (
		<>
			<header className="appHeader">
				<div className="appHeaderContent">
					<div className="appHeaderLogo gapColSmall">
						<Logo />
						<div className="logoType">{t("common.appName")}</div>
					</div>
					<AppNav />
				</div>
			</header>
		</>
	);
}

export default AppHeader;
