//Node Modules
import React, { useState, useEffect, useRef } from "react";

//Pages

//BinaryForge Components

//3rd Party Components
import { Chart, registerables } from "chart.js";

//Atoms

//Services

//Helpers

//Other
import config from "../../config/chart.json";

function PropertiesChart(props) {
	const chart = useRef(null);
	Chart.register(...registerables);

	const { selectedFish, propertyType } = props;

	const [myChart, setMyChart] = useState();
	const [labels, setLabels] = useState();
	const [data, setData] = useState();
	const [chartHeight, setChartHeight] = useState(0);

	useEffect(() => {
		createChart();

		return function cleanup() {
			console.log("Do Cleanup");
			// myChart.destroy();
		};
	}, []);

	useEffect(() => {
		if (selectedFish) {
			const myLabels = [];
			const myData = [];
			setChartHeight(selectedFish.length * config.heightMultiplyer + 60);

			selectedFish.map((fish) => {
				myLabels.push(fish.commonName);
				myData.push([fish[`min${propertyType}`], fish[`max${propertyType}`]]);
			});

			setLabels(myLabels);
			setData(myData);

			const ctx = chart.current.getContext("2d");

			const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
			gradient.addColorStop(0, config.barColour[`${propertyType}`].start);
			gradient.addColorStop(1, config.barColour[`${propertyType}`].end);

			myChart.data.labels = myLabels;
			myChart.data.datasets[0].data = myData;
			myChart.data.datasets[0].backgroundColor = gradient;
			myChart.options.scales.x.min = Math.min(...selectedFish.map((o) => o[`min${propertyType}`]));
			myChart.update();
		}
	}, [selectedFish, propertyType]);

	const createChart = () => {
		if (myChart) {
			console.log("Destryoing Chart");
			myChart.destroy();
		}

		const ctx = chart.current.getContext("2d");

		const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
		gradient.addColorStop(0, config.barColour[`${propertyType}`].start);
		gradient.addColorStop(1, config.barColour[`${propertyType}`].end);

		setMyChart(
			new Chart(ctx, {
				type: "bar",
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							barThickness: config.barThickness,
							maxBarThickness: config.barThickness,
							backgroundColor: gradient,
							// borderWidth: config.borderWidth,
							// borderColor: config.barColour[`${propertyType}`].border,
						},
					],
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					indexAxis: "y",
					plugins: {
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								label: function (context) {
									return `${propertyType} - min: ${context.raw[0]}, max: ${context.raw[1]}`;
								},
							},
						},
					},
					scales: {
						x: {
							grid: {
								borderColor: "rgba(135, 160, 190,0.75)",
								borderWidth: 3,
								color: "rgba(135, 160, 190,0.5)",
							},
							ticks: {
								color: `rgba(${config.color},1)`,
								font: {
									family: "Poppins",
								},
							},
						},
						y: {
							grid: {
								borderColor: "rgba(135, 160, 190,0.75)",
								borderWidth: 3,
								display: false,
							},
							ticks: {
								color: `rgba(${config.color},1)`,
								font: {
									family: "Poppins",
								},
							},
						},
					},
				},
			})
		);
	};

	return (
		<div className="chartContainer" style={{ height: `${chartHeight}px` }}>
			<canvas id="myChart" ref={chart}></canvas>
		</div>
	);
}

export default PropertiesChart;
