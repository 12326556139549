//Node Modules
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Services

//Helpers

//Other
import nav from "../../config/nav.json";

function AppNav() {
	const { t } = useTranslation();

	return (
		<nav className="appNav">
			<Link to={nav.search}>{t("nav.search")}</Link>
			<Link to={nav.charts}>{t("nav.charts")}</Link>
			<Link to={nav.calc.energy}>{t("nav.calc.energy")}</Link>
			{process.env.NODE_ENV === "development" && <Link to={nav.lib}>{t("nav.lib")}</Link>}
		</nav>
	);
}

export default AppNav;
