//Node Modules
import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

//Pages
import { Search, Charts, NotFound, Library } from "./pages";
import { CalcEnergy } from "./pages/calc";

//BinaryForge Components
import { Loading, AppHeader, AppFooter } from "./components/general";

//3rd Party Components

//Atoms

//Services
import { setupAxiosInterceptor, warmUp } from "./services/base";

//Helpers

//Messages & Languages
import "./i18n";

//Other
import nav from "./config/nav.json";

//Styles
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/css/main.css";

import img1 from "./assets/imgs/pageHeader/Bleedind_hear_tetra_original.jpg";
import img2 from "./assets/imgs/pageHeader/Paracyprichromis_nigripinnis_original.jpg";
import img3 from "./assets/imgs/pageHeader/WaterWithout_original.jpg";

function App() {
	//Recoil

	//State
	const [isSetup, setIsSetup] = useState(false);
	const [pageHeader, setPageHeader] = useState(img1);

	useEffect(() => {
		doSetup();
		setIsSetup(true);
	}, []);

	const doSetup = async () => {
		const picArray = [img1, img2, img3];
		setPageHeader(picArray[Math.floor(Math.random() * picArray.length)]);
		await setupAxiosInterceptor();
		await warmUp();
	};

	return (
		<div id="appWrapper" className="appWrapper">
			<div className="imgBackground" style={{ backgroundImage: `url(${pageHeader})` }} />
			<Loading />
			<AppHeader />

			{isSetup && (
				<Switch>
					<Route exact path={nav.home} component={Search} />
					<Route exact path={nav.search} component={Search} />
					<Route exact path={nav.charts} component={Charts} />

					<Route exact path={nav.calc.energy} component={CalcEnergy} />

					<Route exact path={nav.lib} component={Library} />

					<Route component={NotFound} />
				</Switch>
			)}

			<AppFooter />
		</div>
	);
}

export default App;
