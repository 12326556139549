//Node Modules
import React from "react";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Services

//Helpers

//Other

function PageHeader({ title, subtitle }) {
	return (
		<div className="pageHeader">
			<div className="pageHeaderContent">
				<h1>{title}</h1>
				<h2>{subtitle}</h2>
			</div>
		</div>
	);
}

export default PageHeader;
