//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader } from "../components/general";

//3rd Party Components

//Atoms

//Services

//Helpers

//Other

function Landing() {
	const { t } = useTranslation();

	//Recoil

	//State

	return (
		<>
			<PageHeader title={t("common.appName")} />
			<main>
				<div className="content"></div>
			</main>
		</>
	);
}

export default Landing;
