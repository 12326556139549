import axios from "axios";

export const getAllFish = () => {
	return axios.get("/fish");
};

export const getAllFishParameters = () => {
	return axios.get("/fish/parameters");
};

export const searchFish = (params) => {
	return axios.post("/fish/search", params);
};
