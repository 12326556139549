//Node Modules
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//Pages

//BinaryForge Components
import Expire from "../general/Expire";
import Tooltip from "../general/Tooltip";

//3rd Party Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { OverlayPanel } from "primereact/overlaypanel";

//Atoms
import { searchedFishAtom } from "../../atoms";

//Services

//Helpers
import { numberFilterFunction } from "../../helpers/FilterFunctions";

//Other

function ResultTable() {
	const { t } = useTranslation();
	const op = useRef(null);

	//Recoil
	const fish = useRecoilValue(searchedFishAtom);

	// let frozenHeaderGroup = (
	// 	<ColumnGroup>
	// 		<Row>
	// 			<Column field="commonName" header="Common Name" rowSpan={2} sortable={true} filter={true} filterMatchMode="contains" className="columnName" />
	// 		</Row>
	// 	</ColumnGroup>
	// );

	let headerGroup = (
		<ColumnGroup>
			<Row>
				<Column field="commonName" header="Common Name" rowSpan={2} sortable={true} filter={true} filterMatchMode="contains" />
				<Column field="latinName" header="Scientific Name" rowSpan={2} sortable={true} filter={true} filterMatchMode="contains" />
				<Column field="fullGrownSize" header="Adult Size (mm) *" rowSpan={2} sortable={true} filter={true} filterMatchMode="custom" className="columnSize" />
				<Column header="Min Tank Size (cm)" colSpan={3} />
				<Column header="Tank Temps (&deg;C)" colSpan={2} className="temp" />
				<Column header="Ph" colSpan={2} className="ph" />
				<Column header="Water Hardness (&deg;GH)" colSpan={2} className="gh" />
			</Row>
			<Row>
				<Column field="tankMinLength" header="Length" sortable={true} className="columnParameter" />
				<Column field="tankMinDepth" header="Depth" sortable={true} className="columnParameter" />
				<Column field="tankMinHeight" header="Height" sortable={true} className="columnParameter" />
				<Column field="minWaterTemp" header="Min" sortable={true} className="temp" />
				<Column field="maxWaterTemp" header="Max" sortable={true} className="temp" />
				<Column field="minPh" header="Min" sortable={true} className="ph columnParameter" />
				<Column field="maxPh" header="Max" sortable={true} className="ph columnParameter" />
				<Column field="minHardness" header="Min" sortable={true} className="gh columnParameter" />
				<Column field="maxHardness" header="Max" sortable={true} className="gh columnParameter" />
			</Row>
		</ColumnGroup>
	);

	const phMinTemplate = (rowData) => {
		return <span className="ph">{rowData.minPh.toFixed(1)}</span>;
	};

	const phMaxTemplate = (rowData) => {
		return <span className="ph">{rowData.maxPh.toFixed(1)}</span>;
	};

	const handleScroll = () => {
		console.log("scrolled");
	};

	// useEffect(() => {
	// 	if (op.current) {
	// 		console.log(op.current);
	// 		op.current.show();
	// 	}
	// }, [op]);

	const showTooltip = (e) => {
		op.current.show(e);
	};

	return (
		<>
			<div className="horizScrollTooltip flex justifyEnd marginBottomSmall" onClick={(e) => showTooltip(e)}>
				<Expire delay="5500">
					<span className="scrollArrowRight">&gt;</span>
					<span className="scrollArrowRight">&gt;</span>
					<span className="scrollArrowRight">&gt;</span>
					<Expire delay="5000">
						<Tooltip>Scroll for more info</Tooltip>
					</Expire>
				</Expire>
			</div>
			<DataTable
				value={fish}
				headerColumnGroup={headerGroup}
				scrollable
				emptyMessage="No Fish Match Your Parameters"
				sortMode="multiple"
				removableSort
				// frozenHeaderColumnGroup={frozenHeaderGroup}
				// frozenWidth="200px"
				className="searchTable">
				<Column
					className="columnName"
					field="commonName"
					// body={commonNameTemplate}
					sortable={true}
					sortField="fish.commonName"
					style={{ width: "200px" }}
					// frozen
				/>
				<Column className="columnName" field="latinName" style={{ width: "200px" }} />
				<Column field="fullGrownSize" className="columnSize" style={{ width: "180px" }} filterFunction={numberFilterFunction} />

				<Column field="tankMinLength" className="columnParam dims" style={{ width: "105px" }} />
				<Column field="tankMinDepth" className="columnParam dims" style={{ width: "95px" }} />
				<Column field="tankMinHeight" className="columnParam dims" style={{ width: "120px" }} />
				<Column field="minTemp" className="columnParam temp" style={{ width: "80px" }} />
				<Column field="maxTemp" className="columnParam temp" style={{ width: "120px" }} />
				<Column field="minPh" body={phMinTemplate} className="columnParam phColumnMin" style={{ width: "80px" }} />
				<Column field="maxPh" body={phMaxTemplate} className="columnParam phColumnMax" style={{ width: "120px" }} />
				<Column field="minHardness" className="columnParam gh" style={{ width: "80px" }} />
				<Column field="maxHardness" className="columnParam gh" style={{ width: "120px" }} />
			</DataTable>
		</>
	);
}

export default ResultTable;
