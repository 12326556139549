//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components

//3rd Party Components

//Atoms

//Services

//Helpers

//Other

function NotFound() {
	const { t } = useTranslation();

	return (
		<>
			<header>{t("notFound.notFound")}</header>
		</>
	);
}

export default NotFound;
