import { atom, atomFamily, selectorFamily } from "recoil";

export const equipmentAtomFamily = atomFamily({
	key: "equipmentAtomFamily",
	default: { name: null, type: null, wattage: null, hours: null },
});

// abstraction
export const equipmentAtomFamilyAccess = selectorFamily({
	key: "equipmentAtomFamilyAccess",
	get:
		(id) =>
		({ get }) => {
			const atom = get(equipmentAtomFamily(id));
			return atom;
		},
});

export const energyCostAtom = atom({
	key: "energyCostAtom",
	default: 10,
});

export const energyUsageAtom = atom({
	key: "energyUsageAtom",
	default: 0,
});
