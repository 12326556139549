//Node Modules
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { AppNav } from "./";

//3rd Party Components

//Atoms

//Services

//Helpers

//Other

function AppFooter() {
	const { t } = useTranslation();

	return (
		<>
			<footer className="appFooter paddingTopXLarge">
				<div className="content">
					<div className="appDetails marginBottomLarge">
						<h2 className="marginBottomXSmall">{t("common.appName")}</h2>
						<span className="marginBottomMedium">{t("common.appDesc")}</span>
						<AppNav />
					</div>
					<div className="legal">
						<span>
							{t("common.appName")} v{process.env.REACT_APP_VERSION} | &copy; {new Date().getFullYear()} {t("common.company")}
						</span>
						<div>
							<Link to="/">{t("footer.privacy")}</Link>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default AppFooter;
