import SearchFish from "../../pages/Search";

export const engb = {
	common: {
		company: "tropicalfishbase.co.uk",
		appName: "Tropical FishBase",
		appDesc: "A description of what the app is - like a strapline",
		loading: "Loading",
	},
	nav: {
		home: "Home",
		search: "Search",
		charts: "Charts",
		lib: "Library",
		calc: {
			energy: "Energy",
		},
	},
	notFound: {
		notFound: "Not Found",
	},
	pageSearch: {
		title: "Search",
		subtitle: "Need to find fish suitable for your aquarium and water parameters? Just fill in the relevant information below and see what awaits.",
		form: {
			submit: "Search for Fish",
			submitAlt: "Show Me Everything",
			tankDims: "Tank Dimensions",
			tankParams: "Tank Parameters",
			tankLength: {
				label: "Tank Length (cm)",
				placeholder: "Enter your tanks longest dimension",
				required: "Tank Length is required",
				min: "Tank Length must be at least 1cm",
			},
			tankDepth: {
				label: "Tank Depth (cm)",
				placeholder: "Enter your tanks front to back dimension",
				required: "Tank Depth is required",
				min: "Tank Depth must be at least 1cm",
			},
			tankHeight: {
				label: "Tank Height (cm)",
				placeholder: "Enter you tanks top to bottom dimension",
				required: "Tank Height is required",
				min: "Tank Height must be at least 1cm",
			},
			paramPh: {
				label: "Tank pH",
				placeholder: "Enter you tanks pH value",
				min: "Tank pH must be at least 1",
				max: "Tank pH cannot be more than 14",
			},
			paramGh: {
				label: "Tank Hardness (dGH)",
				placeholder: "Enter you tanks GH value",
				min: "Tank GH must be at least 1cm",
			},
			paramTemp: {
				label: "Tank Temperature (°C)",
				placeholder: "Enter you tanks average temperature",
				min: "Tank Temperature must be at least 1°C",
			},
		},
	},
	pageCharts: {
		title: "Parameter Charts",
		subtitle: "Compare ph, Gh and Temparture ranges for a selection of fish.",
		form: {
			selectFish: {
				label: "Select Fish",
			},
		},
	},
	pageCalcEnergy: {
		title: "Energy Cost Calculator",
		subtitle: "",
		add: "Add Equipment",
		form: {
			submit: "Save",
			cost: {
				label: "Energy Cost (pence / kWh)",
				placeholder: "Enter you energy cost",
			},
			name: {
				label: "Equipment Name",
				placeholder: "Enter a name for the equipmemt",
				required: "Equipment name is required",
			},
			type: {
				label: "Equipment Type",
				placeholder: "Select equipment type",
				required: "Equipment type is required",
			},
			wattage: {
				label: "Wattage",
				placeholder: "Enter equipment wattage",
				required: "Wattage is required",
				min: "Wattage must be at least 1",
			},
			hours: {
				label: "Running Hours",
				placeholder: "Enter number of running hours per day",
				required: "Hours are required",
				min: "Running Hours must be at least 1",
				max: "Running Hours cannot be more than 24",
			},
		},
	},
	pageLibrary: {
		title: "Component Library",
		subtitle: "A place to make sure styles are consistent",
		placeholder: "Placeholder",
	},
};
