// Filter for number columns
export const numberFilterFunction = (value, filter) => {
	const num = filter.substr(1);
	if (filter.charAt(0) === "<") {
		return value < num;
	} else if (filter.charAt(0) === ">") {
		return value > num;
	} else {
		return parseFloat(value) === parseFloat(filter);
	}
};
