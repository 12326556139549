//Node Modules
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

//Pages

//BinaryForge Components
import { PageHeader } from "../../components/general";
import Equipment from "../../components/calc/energy/Equipment";

//3rd Party Components
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

//Atoms
import { energyCostAtom, energyUsageAtom } from "../../atoms/EquipmentAtom";

//Services

//Helpers

//Other

function CalcEnergy() {
	const { t } = useTranslation();

	//Recoil
	const [energyCost, setEnergyCost] = useRecoilState(energyCostAtom);
	const [energyUsage, setEnergyUsage] = useRecoilState(energyUsageAtom);

	//State
	const [equipment, setEquipment] = useState([]);

	// const calcEnergyUsage = (wattage, hours) => {
	// 	return (wattage / 1000) * hours;
	// };

	const getTotalDailyCost = () => {
		return (energyUsage * energyCost) / 100;
	};

	const addEquipment = () => {
		const newEquipment = { id: uuidv4() };
		setEquipment((equipment) => [...equipment, newEquipment]);
	};

	return (
		<>
			<PageHeader title={t("pageCalcEnergy.title")} subtitle={t("pageCalcEnergy.subtitle")} />
			<main>
				<section>
					<div className="content card">
						<div className="column2 autoColumns alignCenter marginBottomLarge divider spaceBetween">
							<h2 className="">Your Equipment...</h2>
							<Button type="submit" className="feature" onClick={() => addEquipment()}>
								{t("pageCalcEnergy.add")}
							</Button>
						</div>
						<div className="flexCol gapRowMedium">
							{equipment.map((light) => {
								return <Equipment id={light.id} />;
							})}
						</div>
					</div>
				</section>
				<section>
					<div className="content card">
						<div className="equipmentCostTotal">
							<div className="column2 autoColumns alignCenter marginBottomLarge divider spaceBetween">
								<h2 className="">Total Running Cost...</h2>
								<div className="formField">
									<label htmlFor="cost">{t("pageCalcEnergy.form.cost.label")}</label>
									<InputNumber
										placeholder={t("pageCalcEnergy.form.cost.placeholder")}
										id="cost"
										name="cost"
										showButtons
										value={energyCost}
										onChange={(e) => setEnergyCost(e.value)}
										min={1}
										max={100}
									/>
								</div>
							</div>
							<div className="gridColumns spaceAround center runningCosts">
								<div>
									<h2 className="marginBottomSmall">Daily</h2>
									<h2>£{getTotalDailyCost().toFixed(2)}</h2>
								</div>
								<div>
									<h2 className="marginBottomSmall">Weekly</h2>
									<h2>£{((getTotalDailyCost() * 365) / 52).toFixed(2)}</h2>
								</div>
								<div>
									<h2 className="marginBottomSmall">Monthly</h2>
									<h2>£{((getTotalDailyCost() * 365) / 12).toFixed(2)}</h2>
								</div>
								<div>
									<h2 className="marginBottomSmall">Yearly</h2>
									<h2>£{(getTotalDailyCost() * 365).toFixed(2)}</h2>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}

export default CalcEnergy;
