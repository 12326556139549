import React, { useEffect, useState, useRef } from "react";

const Tooltip = (props) => {
	const tt = useRef(null);

	const [offset, setOffset] = useState();

	useEffect(() => {
		setOffset((tt.current.clientWidth / 2) * -1 + tt.current.parentElement.clientWidth / 2);
	});

	return (
		<div ref={tt} className="tfbTooltip" style={{ left: offset }}>
			{props.children}
		</div>
	);
};

export default Tooltip;
