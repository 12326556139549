import axios from "axios";

//Other
const BASE_URL = process.env.REACT_APP_API_AWS;
let myInterceptor = null;

export const setupAxiosInterceptor = async () => {
	myInterceptor = axios.interceptors.request.use((config) => {
		config.baseURL = BASE_URL;
		config.headers["Content-Type"] = "application/json";
		return config;
	});
};

export const ejectAxiosInterceptor = () => {
	axios.interceptors.request.eject(myInterceptor);
};

export const warmUp = () => {
	return axios.get("/warmup");
};
