const Logo = () => (
	<div className="logoImg">
		<svg width="100%" height="100%" viewBox="0 0 420 500" preserveAspectRatio="xMidYMid meet">
			<defs>
				<clipPath id="logoMask" transform="scale(0.0024, 0.002)" clipPathUnits="objectBoundingBox">
					<path d="M79.562,103.663c-2.499,-1.894 -3.967,-4.849 -3.967,-7.984l0,-75.318c0,-7.73 4.377,-14.793 11.3,-18.234c6.922,-3.44 15.196,-2.663 21.357,2.006l303.075,229.638c5.08,3.849 8.065,9.855 8.065,16.229c0,6.374 -2.985,12.38 -8.065,16.229l-303.075,229.638c-6.161,4.669 -14.435,5.446 -21.357,2.006c-6.923,-3.441 -11.3,-10.504 -11.3,-18.234l0,-75.318c0,-3.135 1.468,-6.09 3.967,-7.984c7.032,-5.328 21.745,-16.476 30.269,-22.935c1.224,-0.927 2.867,-1.081 4.242,-0.398c1.375,0.683 2.244,2.086 2.244,3.622c0,13.537 0,40.525 0,53.998c0,1.517 0.859,2.903 2.218,3.578c1.358,0.675 2.982,0.523 4.191,-0.393c35.662,-27.021 209.102,-158.436 238.386,-180.624c0.997,-0.756 1.583,-1.934 1.583,-3.185c0,-1.251 -0.586,-2.429 -1.583,-3.185c-29.284,-22.188 -202.724,-153.603 -238.386,-180.624c-1.209,-0.916 -2.833,-1.068 -4.191,-0.393c-1.359,0.675 -2.218,2.061 -2.218,3.578c0,13.473 0,40.461 0,53.998c0,1.536 -0.869,2.939 -2.244,3.622c-1.375,0.683 -3.018,0.529 -4.242,-0.398c-8.524,-6.459 -23.237,-17.607 -30.269,-22.935Z" />
					<path d="M183.847,266.229c5.08,-3.849 8.065,-9.855 8.065,-16.229c0,-6.374 -2.985,-12.38 -8.065,-16.229l-151.189,-114.555c-6.161,-4.669 -14.435,-5.445 -21.358,-2.005c-6.922,3.44 -11.3,10.503 -11.3,18.234l0,229.11c0,7.731 4.378,14.794 11.3,18.234c6.923,3.44 15.197,2.664 21.358,-2.005l151.189,-114.555Zm-143.124,51.266c0,1.148 0.65,2.198 1.678,2.709c1.029,0.511 2.258,0.395 3.173,-0.298c17.182,-13.019 74.872,-56.731 89.079,-67.495c0.755,-0.572 1.198,-1.464 1.198,-2.411c0,-0.947 -0.443,-1.839 -1.198,-2.411c-14.207,-10.764 -71.897,-54.476 -89.079,-67.495c-0.915,-0.693 -2.144,-0.809 -3.173,-0.298c-1.028,0.511 -1.678,1.561 -1.678,2.709c0,23.89 0,111.1 0,134.99Z" />
				</clipPath>
			</defs>
		</svg>
	</div>
);

export default Logo;
