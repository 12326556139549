//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { useForm, Controller } from "react-hook-form";

//Pages

//BinaryForge Components

//3rd Party Components
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

//Atoms
import { loadingAtom } from "../../atoms";
import { searchedFishAtom } from "../../atoms";

//Services
import { getAllFish, searchFish } from "../../services/fish";

//Helpers

//Other

function SearchForm() {
	const { t } = useTranslation();

	//Recoil
	const setLoading = useSetRecoilState(loadingAtom);
	const setFish = useSetRecoilState(searchedFishAtom);

	//React Hook Form
	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="small error">{errors[name].message}</span>;
	};

	const onSubmitSearch = async (data) => {
		const params = {
			tankLength: data.tankLength,
			tankDepth: data.tankDepth,
			tankHeight: data.tankHeight,
			parameters: {
				Ph: data.paramPh,
				Gh: data.paramGh,
				Temp: data.paramTemp,
			},
		};

		setLoading({ visible: true, message: t("common.loading") });

		try {
			const resp = await searchFish(params);
			setFish(resp.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const onSubmitAll = async () => {
		setLoading({ visible: true, message: t("common.loading") });

		try {
			const resp = await getAllFish();
			setFish(resp.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmitSearch)}>
			<div className="column2">
				<div className="flexCol gapRowMedium">
					<h3 className="divider">{t("pageSearch.form.tankDims")}</h3>
					<div className="formField">
						<label htmlFor="tankLength">{t("pageSearch.form.tankLength.label")}</label>
						<Controller
							name="tankLength"
							control={control}
							rules={{
								required: { value: true, message: t("pageSearch.form.tankLength.required") },
								min: { value: 1, message: t("pageSearch.form.tankLength.min") },
							}}
							render={({ field, fieldState }) => (
								<InputNumber
									placeholder={t("pageSearch.form.tankLength.placeholder")}
									showButtons
									id={field.name}
									value={field.value}
									min={1}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("tankLength")}
					</div>
					<div className="formField">
						<label htmlFor="tankDepth">{t("pageSearch.form.tankDepth.label")}</label>
						<Controller
							name="tankDepth"
							control={control}
							rules={{
								required: { value: true, message: t("pageSearch.form.tankDepth.required") },
								min: { value: 1, message: t("pageSearch.form.tankDepth.min") },
							}}
							render={({ field, fieldState }) => (
								<InputNumber
									placeholder={t("pageSearch.form.tankDepth.placeholder")}
									showButtons
									id={field.name}
									value={field.value}
									min={1}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("tankDepth")}
					</div>
					<div className="formField">
						<label htmlFor="tankHeight">{t("pageSearch.form.tankHeight.label")}</label>
						<Controller
							name="tankHeight"
							control={control}
							rules={{
								required: { value: true, message: t("pageSearch.form.tankHeight.required") },
								min: { value: 1, message: t("pageSearch.form.tankHeight.min") },
							}}
							render={({ field, fieldState }) => (
								<InputNumber
									placeholder={t("pageSearch.form.tankHeight.placeholder")}
									showButtons
									id={field.name}
									value={field.value}
									min={1}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("tankHeight")}
					</div>
				</div>
				<div className="flexCol gapRowMedium">
					<h3 className="divider">{t("pageSearch.form.tankParams")}</h3>
					<div className="formField">
						<label htmlFor="paramPh">{t("pageSearch.form.paramPh.label")}</label>
						<Controller
							name="paramPh"
							control={control}
							rules={{
								min: { value: 1, message: t("pageSearch.form.paramPh.min") },
								max: { value: 14, message: t("pageSearch.form.paramPh.max") },
							}}
							render={({ field, fieldState }) => (
								<InputNumber
									placeholder={t("pageSearch.form.paramPh.placeholder")}
									showButtons
									id={field.name}
									value={field.value}
									min={1}
									max={14}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("paramPh")}
					</div>
					<div className="formField">
						<label htmlFor="paramGh">{t("pageSearch.form.paramGh.label")}</label>
						<Controller
							name="paramGh"
							control={control}
							rules={{
								min: { value: 1, message: t("pageSearch.form.paramGh.min") },
							}}
							render={({ field, fieldState }) => (
								<InputNumber
									placeholder={t("pageSearch.form.paramGh.placeholder")}
									showButtons
									id={field.name}
									value={field.value}
									min={1}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("paramGh")}
					</div>
					<div className="formField">
						<label htmlFor="paramTemp">{t("pageSearch.form.paramTemp.label")}</label>
						<Controller
							name="paramTemp"
							control={control}
							rules={{
								min: { value: 1, message: t("pageSearch.form.paramTemp.min") },
							}}
							render={({ field, fieldState }) => (
								<InputNumber
									placeholder={t("pageSearch.form.paramTemp.placeholder")}
									showButtons
									id={field.name}
									value={field.value}
									min={1}
									onChange={(e) => field.onChange(e.value)}
									className={classNames({ error: fieldState.invalid })}
								/>
							)}
						/>
						{getFormErrorMessage("paramTemp")}
					</div>
				</div>
			</div>
			<div className="flex contentEnd gapColSmall marginTopSmall">
				<Button type="button" onClick={() => onSubmitAll()}>
					{t("pageSearch.form.submitAlt")}
				</Button>
				<Button type="submit" className="feature">
					{t("pageSearch.form.submit")}
				</Button>
			</div>
		</form>
	);
}

export default SearchForm;
