//Node Modules
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader } from "../components/general";

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";

import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";

//Atoms

//Services

//Helpers

//Other
const selectItems = [
	{ label: "New York", value: "NY" },
	{ label: "Rome", value: "RM" },
	{ label: "London", value: "LDN" },
	{ label: "Istanbul", value: "IST" },
	{ label: "Paris", value: "PRS" },
];

const selBttnItems = [
	{ name: "Ph", value: "Ph" },
	{ name: "Gh", value: "Hardness" },
	{ name: "Temp", value: "Temp" },
];

function Library() {
	const { t } = useTranslation();

	const [inputTextV, setInputTextV] = useState();
	const [inputNumV, setInputNumV] = useState();
	const [inputDropV, setInputDropV] = useState();
	const [inputMultiV, setInputMultiV] = useState();
	const [inputSelBttn, setInputSelBttn] = useState("Ph");

	return (
		<>
			<PageHeader title={t("pageLibrary.title")} subtitle={t("pageLibrary.subtitle")} />
			<main>
				<div className="content column3 card">
					<div className="formField">
						<label>Input Text</label>
						<InputText placeholder={t("pageLibrary.placeholder")} value={inputTextV} onChange={(e) => setInputTextV(e.target.value)} />
					</div>
					<div className="formField">
						<label>Input Number</label>
						<InputNumber placeholder={t("pageLibrary.placeholder")} value={inputNumV} onValueChange={(e) => setInputNumV(e.value)} showButtons />
					</div>
					<div className="formField"></div>

					<Button label="Save" />
					<Button label="Save" className="feature" />
					<Button label="Save" className="maxContent" />

					<div className="formField">
						<label>Dropdown</label>
						<Dropdown value={inputDropV} options={selectItems} onChange={(e) => setInputDropV(e.value)} placeholder="Select an Option" />
					</div>
					<div className="formField">
						<label>Multiselect</label>
						<MultiSelect value={inputMultiV} options={selectItems} onChange={(e) => setInputMultiV(e.value)} placeholder="Select Options" />
					</div>
					<div className="formField"></div>

					<SelectButton value={inputSelBttn} options={selBttnItems} optionLabel="name" onChange={(e) => setInputSelBttn(e.value)} />
				</div>
			</main>
		</>
	);
}

export default Library;
