//Node Modules
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

//Pages

//BinaryForge Components
import { PageHeader } from "../components/general";
import { Form, ResultTable } from "../components/search";

//3rd Party Components

//Atoms
import { searchedFishAtom } from "../atoms";

//Services

//Helpers

function SearchFish() {
	const { t } = useTranslation();

	//Recoil
	const fish = useRecoilValue(searchedFishAtom);

	return (
		<>
			<PageHeader title={t("pageSearch.title")} subtitle={t("pageSearch.subtitle")} />
			<main>
				<section>
					<div className="content card">
						<Form />
					</div>
				</section>
				{fish && (
					<section>
						<div className="content card">
							<div className="marginBottomMedium divider">
								<h2 className="">Your Results...</h2>
								<p>
									Found <b>{fish.length}</b> suitable fish.
								</p>
							</div>
							<ResultTable />
						</div>
					</section>
				)}
			</main>
		</>
	);
}

export default SearchFish;
