//Node Modules
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

//Pages

//BinaryForge Components

//3rd Party Components
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

//Atoms
import { equipmentAtomFamily, energyCostAtom, energyUsageAtom } from "../../../atoms/EquipmentAtom";

//Services

//Helpers

//Other
const equipmentOptions = [
	{ label: "Lighting", value: "light" },
	{ label: "Heater", value: "heat" },
	{ label: "Filtration", value: "filter" },
	{ label: "Other", value: "other" },
];

const equipmentOptionsMap = { light: "Lighting", heat: "Heater", filter: "Filtration", other: "Other" };

function Equipment({ id }) {
	const { t } = useTranslation();

	//Recoil
	const [equipment, setEquipment] = useRecoilState(equipmentAtomFamily(id));
	const setEnergyUsage = useSetRecoilState(energyUsageAtom);
	const energyCost = useRecoilValue(energyCostAtom);

	//State
	const [edit, setEdit] = useState(true);

	const defaultValues = {
		type: equipment.type,
		name: equipment.name,
		wattage: equipment.wattage,
		hours: equipment.hours,
	};

	//React Hook Form
	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		mode: "onTouched",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues,
	});

	const getFormErrorMessage = (name) => {
		return errors[name] && <span className="small error">{errors[name].message}</span>;
	};

	const onAddItem = async (data) => {
		const calcEnergyUsage = (data.hours * data.wattage) / 1000;
		setEquipment(data);
		setEnergyUsage((prevValue) => prevValue + calcEnergyUsage);
		setEdit(false);
	};

	return (
		<>
			{!edit ? (
				<div className="equipmentWrapper gapMedium">
					<div className="equipmentIcon">
						<span className={`pi pi-times icon icon-${equipment.type}`}></span>
						<span className={`icon-${equipment.type}`}>{equipmentOptionsMap[equipment.type]}</span>
					</div>
					<div className="equipmentDetails">
						<span>{equipment.name}</span>
						<span className="right">
							{equipment.wattage}
							<span className="light small marginLeftXSmall">Watts</span>
						</span>
						<span className="right">
							{equipment.hours}
							<span className="light small marginLeftXSmall">hours / day</span>
						</span>
					</div>
					<div className="equipmentCost">
						<p className="right">
							£{((equipment.hours * (equipment.wattage / 1000) * energyCost) / 100).toFixed(2)}
							<span className="light small marginLeftXSmall"> /day</span>
						</p>
					</div>
					<span className="pi pi-pencil icon" onClick={() => setEdit(true)}></span>
				</div>
			) : (
				<form onSubmit={handleSubmit(onAddItem)}>
					<div className="equipmentEditWrapper gapMedium alignBottom">
						<div className="formField">
							<label htmlFor="type">{t("pageCalcEnergy.form.type.label")}</label>
							<Controller
								name="type"
								control={control}
								rules={{
									required: { value: true, message: t("pageCalcEnergy.form.type.required") },
								}}
								render={({ field, fieldState }) => (
									<Dropdown
										placeholder={t("pageCalcEnergy.form.type.placeholder")}
										id={field.name}
										value={field.value}
										options={equipmentOptions}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ error: fieldState.invalid })}
									/>
								)}
							/>
							{getFormErrorMessage("type")}
						</div>

						<div className="formField">
							<label htmlFor="name">{t("pageCalcEnergy.form.name.label")}</label>
							<Controller
								name="name"
								control={control}
								rules={{
									required: { value: true, message: t("pageCalcEnergy.form.name.required") },
								}}
								render={({ field, fieldState }) => (
									<InputText
										placeholder={t("pageCalcEnergy.form.name.placeholder")}
										id={field.name}
										{...field}
										className={classNames({ error: fieldState.invalid })}
									/>
								)}
							/>
							{getFormErrorMessage("name")}
						</div>

						<div className="formField">
							<label htmlFor="wattage">{t("pageCalcEnergy.form.wattage.label")}</label>
							<Controller
								name="wattage"
								control={control}
								rules={{
									required: { value: true, message: t("pageCalcEnergy.form.wattage.required") },
									min: { value: 1, message: t("pageCalcEnergy.form.wattage.min") },
								}}
								render={({ field, fieldState }) => (
									<InputNumber
										placeholder={t("pageCalcEnergy.form.wattage.placeholder")}
										showButtons
										id={field.name}
										value={field.value}
										size={4}
										min={1}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ error: fieldState.invalid })}
									/>
								)}
							/>
							{getFormErrorMessage("wattage")}
						</div>

						<div className="formField">
							<label htmlFor="hours">{t("pageCalcEnergy.form.hours.label")}</label>
							<Controller
								name="hours"
								control={control}
								rules={{
									required: { value: true, message: t("pageCalcEnergy.form.hours.required") },
									min: { value: 1, message: t("pageCalcEnergy.form.hours.min") },
									max: { value: 24, message: t("pageCalcEnergy.form.hours.max") },
								}}
								render={({ field, fieldState }) => (
									<InputNumber
										placeholder={t("pageCalcEnergy.form.hours.placeholder")}
										showButtons
										id={field.name}
										value={field.value}
										size={4}
										min={1}
										max={24}
										onChange={(e) => field.onChange(e.value)}
										className={classNames({ error: fieldState.invalid })}
									/>
								)}
							/>
							{getFormErrorMessage("hours")}
						</div>

						<Button type="submit" className="feature">
							{t("pageCalcEnergy.form.submit")}
						</Button>
					</div>
				</form>
			)}
		</>
	);
}

export default Equipment;
