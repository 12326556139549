//Node Modules
import React from "react";
import { useRecoilState } from "recoil";

//Pages

//BinaryForgeComponenets

//3rd Party Components
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

//Atoms
import { loadingAtom } from "../../atoms/LoadingAtom";

//Services

//Helpers

//Other
const appRoot = document.getElementById("root");

function Loading() {
	const [showModal, setShowModal] = useRecoilState(loadingAtom);

	return (
		<Dialog
			visible={showModal.visible}
			closable={false}
			closeOnEscape={false}
			showHeader={false}
			className="loadingDialog"
			appendTo={appRoot}
			onHide={() => setShowModal({ visible: false })}>
			<ProgressSpinner strokeWidth="4" />
			<p className="message">
				{showModal.message}
				<span className="loadingDot">.</span>
				<span className="loadingDot">.</span>
				<span className="loadingDot">.</span>
			</p>
		</Dialog>
	);
}

export default Loading;
