import React, { useEffect, useState } from "react";

const Expire = (props) => {
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setVisible(false);
		}, props.delay);
	}, [props.delay]);

	return <div className={`expire ${visible}`}>{props.children}</div>;
};

export default Expire;
