import { atom } from "recoil";

//Other
import testdata from "../config/test.json";

export const searchedFishAtom = atom({
	key: "searchedFishAtom",
	// default: testdata,
	default: null,
});
