//Node Modules
import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

//Pages

//BinaryForge Components
import { PageHeader } from "../components/general";
import { Chart } from "../components/chart";

//3rd Party Components
import { MultiSelect } from "primereact/multiselect";
import { SelectButton } from "primereact/selectbutton";

//Atoms
import { loadingAtom } from "../atoms";

//Services
import { getAllFishParameters } from "../services/fish";

//Helpers

//Other
const options = [
	{ name: "Ph", value: "Ph" },
	{ name: "Gh", value: "Hardness" },
	{ name: "Temp", value: "Temp" },
];

function Landing() {
	const { t } = useTranslation();

	//Recoil
	const setLoading = useSetRecoilState(loadingAtom);

	//State
	const [allFish, setAllFish] = useState();
	const [selectedFish, setSelectedFish] = useState();
	const [propertyType, setPropertyType] = useState("Ph");

	useEffect(() => {
		setLoading({ visible: true, message: t("common.loading") });
		getData();
	}, []);

	useEffect(() => {
		allFish && setInitialSelection();
	}, [allFish]);

	const getData = async () => {
		try {
			const resp = await getAllFishParameters();
			process.env.NODE_ENV === "development" && console.log("Data:", resp.data);
			setAllFish(resp.data);
		} catch (err) {
			console.error("Setup Error:", err);
		} finally {
			setLoading({ visible: false, message: "" });
		}
	};

	const setInitialSelection = async () => {
		setSelectedFishAlpahbetical(allFish.filter((fish) => fish.id < 33));
	};

	const setSelectedFishAlpahbetical = (selection) => {
		if (selection) {
			const ordered = selection.sort((a, b) => a.commonName.localeCompare(b.commonName));
			setSelectedFish(ordered);
		} else setSelectedFish(null);
	};

	const fishOptionTemplate = (option) => {
		return (
			<span>
				{option.commonName} ({option.latinName})
			</span>
		);
	};

	const typeOptionsTemplate = (option) => {
		return <span className={`${option.name.toLowerCase()} bold`}>{option.name}</span>;
	};

	return (
		<>
			<PageHeader title={t("pageCharts.title")} subtitle={t("pageCharts.subtitle")} />
			<main>
				{allFish && (
					<>
						<section>
							<div className="content card column2 autoColumns alignBottom spaceBetween">
								<div className="formField">
									<label htmlFor="password">{t("pageCharts.form.selectFish.label")}</label>
									<MultiSelect
										value={selectedFish}
										options={allFish}
										optionLabel="commonName"
										itemTemplate={fishOptionTemplate}
										scrollHeight="400px"
										filter
										showClear
										onChange={(e) => setSelectedFishAlpahbetical(e.value)}
									/>
								</div>

								<div>
									<SelectButton
										value={propertyType}
										options={options}
										optionLabel="name"
										itemTemplate={typeOptionsTemplate}
										onChange={(e) => setPropertyType(e.value)}
									/>
								</div>
							</div>
						</section>
						<section>
							<div className="content card">
								<Chart selectedFish={selectedFish} propertyType={propertyType} />
							</div>
						</section>
					</>
				)}
			</main>
		</>
	);
}

export default Landing;
